import { default as _91choice_93ivNMZYy9cGMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue?macro=true";
import { default as indexXvElAlVCDbMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/aanbod/index.vue?macro=true";
import { default as _91hash_93uRLKBs23ScMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/einde-looptijd-lening/[hash].vue?macro=true";
import { default as indexWf06dGk8KmMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue?macro=true";
import { default as indexYGMicTYhCyMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/index.vue?macro=true";
import { default as indexz9vtx0YD4FMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/login/index.vue?macro=true";
import { default as reset_45wachtwoordR3v7qmSkldMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/login/reset-wachtwoord.vue?macro=true";
import { default as wachtwoord_45vergeten35fqHm8AvAMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/login/wachtwoord-vergeten.vue?macro=true";
import { default as indexG5iD5khxBaMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue?macro=true";
import { default as indexVCY3LbGOisMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue?macro=true";
import { default as index9QFaVliIwpMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-aanvragen/index.vue?macro=true";
import { default as indexUPLroZGUrVMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue?macro=true";
import { default as index3SU4F8I6JQMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue?macro=true";
import { default as indexxDiPlcF7Z3Meta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-organisaties/index.vue?macro=true";
import { default as indexMIBbE5pXDoMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue?macro=true";
import { default as indexpLjMZl9kuBMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-partnerplan/index.vue?macro=true";
import { default as indexiBIsdPrVyrMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue?macro=true";
import { default as indexFrtEHM1wEiMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue?macro=true";
import { default as index7YfLVKH0xkMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-profiel/index.vue?macro=true";
import { default as indexR75TYQ6TKTMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue?macro=true";
import { default as index5YRgs2tyyLMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mobile/index.vue?macro=true";
import { default as indexKDq9k8K4WGMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue?macro=true";
import { default as indexx7ETKpf6n6Meta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue?macro=true";
import { default as indexRmmPmHSxmTMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mogelijk-fonds/[id]/index.vue?macro=true";
import { default as indexFC19mzWDQQMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue?macro=true";
import { default as indexSLaXwZJPxoMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue?macro=true";
import { default as indexBdeJkSuprZMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue?macro=true";
import { default as indexpMNWc7VEmwMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mogelijk-fonds/index.vue?macro=true";
import { default as erroricVm87JXc2Meta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/ockto/[hash]/error.vue?macro=true";
import { default as indexYvkvN1SOZWMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/ockto/[hash]/index.vue?macro=true";
import { default as qrcodeX9EOXdTMTVMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/ockto/[hash]/qrcode.vue?macro=true";
import { default as succesrqURj07rwuMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/ockto/[hash]/succes.vue?macro=true";
import { default as _91token_93WJMgjsiJF0Meta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/onboarding/[signing_id]/[token].vue?macro=true";
import { default as _91slug_93mW7M3oSMJUMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/onboarding/[slug].vue?macro=true";
import { default as aanvraagX15g3JsqnJMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/onboarding/aanvraag.vue?macro=true";
import { default as _91experience_93liUz7tdu6HMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/onboarding/bedankt/[experience].vue?macro=true";
import { default as _91token_933R55Ft6wYjMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/onfido/[token].vue?macro=true";
import { default as _91hash_93egVQdAGcNPMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/passeren/[hash].vue?macro=true";
import { default as indexZsP8hz0HrvMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/privacy-verklaring/index.vue?macro=true";
import { default as _91fundKey_93BmbFd6XXWWMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue?macro=true";
import { default as aanvraagqoZB1lTr7MMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue?macro=true";
import { default as indexA04FALuRHQMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue?macro=true";
import { default as _91id_933vobOzHkSBMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/sso/[token]/[id].vue?macro=true";
import { default as index3HmMTGpwTrMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/sso/[token]/index.vue?macro=true";
import { default as _91hash_9381ifaZ8MiHMeta } from "/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/verzekeringen/[hash].vue?macro=true";
export default [
  {
    name: _91choice_93ivNMZYy9cGMeta?.name ?? "aanbod-optie-propertyId-hash-choice",
    path: _91choice_93ivNMZYy9cGMeta?.path ?? "/aanbod-optie/:propertyId()/:hash()/:choice()",
    meta: _91choice_93ivNMZYy9cGMeta || {},
    alias: _91choice_93ivNMZYy9cGMeta?.alias || [],
    redirect: _91choice_93ivNMZYy9cGMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue").then(m => m.default || m)
  },
  {
    name: indexXvElAlVCDbMeta?.name ?? "aanbod",
    path: indexXvElAlVCDbMeta?.path ?? "/aanbod",
    meta: indexXvElAlVCDbMeta || {},
    alias: indexXvElAlVCDbMeta?.alias || [],
    redirect: indexXvElAlVCDbMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/aanbod/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93uRLKBs23ScMeta?.name ?? "einde-looptijd-lening-hash",
    path: _91hash_93uRLKBs23ScMeta?.path ?? "/einde-looptijd-lening/:hash()",
    meta: _91hash_93uRLKBs23ScMeta || {},
    alias: _91hash_93uRLKBs23ScMeta?.alias || [],
    redirect: _91hash_93uRLKBs23ScMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/einde-looptijd-lening/[hash].vue").then(m => m.default || m)
  },
  {
    name: indexWf06dGk8KmMeta?.name ?? "fonds-optie-property_id-hash-type",
    path: indexWf06dGk8KmMeta?.path ?? "/fonds-optie/:property_id()/:hash()/:type()",
    meta: indexWf06dGk8KmMeta || {},
    alias: indexWf06dGk8KmMeta?.alias || [],
    redirect: indexWf06dGk8KmMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexYGMicTYhCyMeta?.name ?? "index",
    path: indexYGMicTYhCyMeta?.path ?? "/",
    meta: indexYGMicTYhCyMeta || {},
    alias: indexYGMicTYhCyMeta?.alias || [],
    redirect: indexYGMicTYhCyMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexz9vtx0YD4FMeta?.name ?? "login",
    path: indexz9vtx0YD4FMeta?.path ?? "/login",
    meta: indexz9vtx0YD4FMeta || {},
    alias: indexz9vtx0YD4FMeta?.alias || [],
    redirect: indexz9vtx0YD4FMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45wachtwoordR3v7qmSkldMeta?.name ?? "login-reset-wachtwoord",
    path: reset_45wachtwoordR3v7qmSkldMeta?.path ?? "/login/reset-wachtwoord",
    meta: reset_45wachtwoordR3v7qmSkldMeta || {},
    alias: reset_45wachtwoordR3v7qmSkldMeta?.alias || [],
    redirect: reset_45wachtwoordR3v7qmSkldMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/login/reset-wachtwoord.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45vergeten35fqHm8AvAMeta?.name ?? "login-wachtwoord-vergeten",
    path: wachtwoord_45vergeten35fqHm8AvAMeta?.path ?? "/login/wachtwoord-vergeten",
    meta: wachtwoord_45vergeten35fqHm8AvAMeta || {},
    alias: wachtwoord_45vergeten35fqHm8AvAMeta?.alias || [],
    redirect: wachtwoord_45vergeten35fqHm8AvAMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/login/wachtwoord-vergeten.vue").then(m => m.default || m)
  },
  {
    name: indexG5iD5khxBaMeta?.name ?? "mijn-aanvragen-offerNumber-herkomst",
    path: indexG5iD5khxBaMeta?.path ?? "/mijn-aanvragen/:offerNumber()/herkomst",
    meta: indexG5iD5khxBaMeta || {},
    alias: indexG5iD5khxBaMeta?.alias || [],
    redirect: indexG5iD5khxBaMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue").then(m => m.default || m)
  },
  {
    name: indexVCY3LbGOisMeta?.name ?? "mijn-aanvragen-offerNumber",
    path: indexVCY3LbGOisMeta?.path ?? "/mijn-aanvragen/:offerNumber()",
    meta: indexVCY3LbGOisMeta || {},
    alias: indexVCY3LbGOisMeta?.alias || [],
    redirect: indexVCY3LbGOisMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: index9QFaVliIwpMeta?.name ?? "mijn-aanvragen",
    path: index9QFaVliIwpMeta?.path ?? "/mijn-aanvragen",
    meta: index9QFaVliIwpMeta || {},
    alias: index9QFaVliIwpMeta?.alias || [],
    redirect: index9QFaVliIwpMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-aanvragen/index.vue").then(m => m.default || m)
  },
  {
    name: indexUPLroZGUrVMeta?.name ?? "mijn-organisaties-companyId-iban-overzicht",
    path: indexUPLroZGUrVMeta?.path ?? "/mijn-organisaties/:companyId()/iban-overzicht",
    meta: indexUPLroZGUrVMeta || {},
    alias: indexUPLroZGUrVMeta?.alias || [],
    redirect: indexUPLroZGUrVMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: index3SU4F8I6JQMeta?.name ?? "mijn-organisaties-bedrijfs-gegevens",
    path: index3SU4F8I6JQMeta?.path ?? "/mijn-organisaties/bedrijfs-gegevens",
    meta: index3SU4F8I6JQMeta || {},
    alias: index3SU4F8I6JQMeta?.alias || [],
    redirect: index3SU4F8I6JQMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexxDiPlcF7Z3Meta?.name ?? "mijn-organisaties",
    path: indexxDiPlcF7Z3Meta?.path ?? "/mijn-organisaties",
    meta: indexxDiPlcF7Z3Meta || {},
    alias: indexxDiPlcF7Z3Meta?.alias || [],
    redirect: indexxDiPlcF7Z3Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-organisaties/index.vue").then(m => m.default || m)
  },
  {
    name: indexMIBbE5pXDoMeta?.name ?? "mijn-partnerplan-token-choice",
    path: indexMIBbE5pXDoMeta?.path ?? "/mijn-partnerplan/:token()/:choice()",
    meta: indexMIBbE5pXDoMeta || {},
    alias: indexMIBbE5pXDoMeta?.alias || [],
    redirect: indexMIBbE5pXDoMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpLjMZl9kuBMeta?.name ?? "mijn-partnerplan",
    path: indexpLjMZl9kuBMeta?.path ?? "/mijn-partnerplan",
    meta: indexpLjMZl9kuBMeta || {},
    alias: indexpLjMZl9kuBMeta?.alias || [],
    redirect: indexpLjMZl9kuBMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-partnerplan/index.vue").then(m => m.default || m)
  },
  {
    name: indexiBIsdPrVyrMeta?.name ?? "mijn-profiel-digitaal-identificeren",
    path: indexiBIsdPrVyrMeta?.path ?? "/mijn-profiel/digitaal-identificeren",
    meta: indexiBIsdPrVyrMeta || {},
    alias: indexiBIsdPrVyrMeta?.alias || [],
    redirect: indexiBIsdPrVyrMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue").then(m => m.default || m)
  },
  {
    name: indexFrtEHM1wEiMeta?.name ?? "mijn-profiel-iban-overzicht",
    path: indexFrtEHM1wEiMeta?.path ?? "/mijn-profiel/iban-overzicht",
    meta: indexFrtEHM1wEiMeta || {},
    alias: indexFrtEHM1wEiMeta?.alias || [],
    redirect: indexFrtEHM1wEiMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: index7YfLVKH0xkMeta?.name ?? "mijn-profiel",
    path: index7YfLVKH0xkMeta?.path ?? "/mijn-profiel",
    meta: index7YfLVKH0xkMeta || {},
    alias: index7YfLVKH0xkMeta?.alias || [],
    redirect: index7YfLVKH0xkMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-profiel/index.vue").then(m => m.default || m)
  },
  {
    name: indexR75TYQ6TKTMeta?.name ?? "mijn-profiel-naw-gegevens",
    path: indexR75TYQ6TKTMeta?.path ?? "/mijn-profiel/naw-gegevens",
    meta: indexR75TYQ6TKTMeta || {},
    alias: indexR75TYQ6TKTMeta?.alias || [],
    redirect: indexR75TYQ6TKTMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: index5YRgs2tyyLMeta?.name ?? "mobile",
    path: index5YRgs2tyyLMeta?.path ?? "/mobile",
    meta: index5YRgs2tyyLMeta || {},
    alias: index5YRgs2tyyLMeta?.alias || [],
    redirect: index5YRgs2tyyLMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mobile/index.vue").then(m => m.default || m)
  },
  {
    name: indexKDq9k8K4WGMeta?.name ?? "mogelijk-fonds-id-deelname",
    path: indexKDq9k8K4WGMeta?.path ?? "/mogelijk-fonds/:id()/deelname",
    meta: indexKDq9k8K4WGMeta || {},
    alias: indexKDq9k8K4WGMeta?.alias || [],
    redirect: indexKDq9k8K4WGMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue").then(m => m.default || m)
  },
  {
    name: indexx7ETKpf6n6Meta?.name ?? "mogelijk-fonds-id-documenten",
    path: indexx7ETKpf6n6Meta?.path ?? "/mogelijk-fonds/:id()/documenten",
    meta: indexx7ETKpf6n6Meta || {},
    alias: indexx7ETKpf6n6Meta?.alias || [],
    redirect: indexx7ETKpf6n6Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue").then(m => m.default || m)
  },
  {
    name: indexRmmPmHSxmTMeta?.name ?? "mogelijk-fonds-id",
    path: indexRmmPmHSxmTMeta?.path ?? "/mogelijk-fonds/:id()",
    meta: indexRmmPmHSxmTMeta || {},
    alias: indexRmmPmHSxmTMeta?.alias || [],
    redirect: indexRmmPmHSxmTMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mogelijk-fonds/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFC19mzWDQQMeta?.name ?? "mogelijk-fonds-id-inschrijving",
    path: indexFC19mzWDQQMeta?.path ?? "/mogelijk-fonds/:id()/inschrijving",
    meta: indexFC19mzWDQQMeta || {},
    alias: indexFC19mzWDQQMeta?.alias || [],
    redirect: indexFC19mzWDQQMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue").then(m => m.default || m)
  },
  {
    name: indexSLaXwZJPxoMeta?.name ?? "mogelijk-fonds-id-ondertekenen",
    path: indexSLaXwZJPxoMeta?.path ?? "/mogelijk-fonds/:id()/ondertekenen",
    meta: indexSLaXwZJPxoMeta || {},
    alias: indexSLaXwZJPxoMeta?.alias || [],
    redirect: indexSLaXwZJPxoMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue").then(m => m.default || m)
  },
  {
    name: indexBdeJkSuprZMeta?.name ?? "mogelijk-fonds-id-overmaken",
    path: indexBdeJkSuprZMeta?.path ?? "/mogelijk-fonds/:id()/overmaken",
    meta: indexBdeJkSuprZMeta || {},
    alias: indexBdeJkSuprZMeta?.alias || [],
    redirect: indexBdeJkSuprZMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue").then(m => m.default || m)
  },
  {
    name: indexpMNWc7VEmwMeta?.name ?? "mogelijk-fonds",
    path: indexpMNWc7VEmwMeta?.path ?? "/mogelijk-fonds",
    meta: indexpMNWc7VEmwMeta || {},
    alias: indexpMNWc7VEmwMeta?.alias || [],
    redirect: indexpMNWc7VEmwMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/mogelijk-fonds/index.vue").then(m => m.default || m)
  },
  {
    name: erroricVm87JXc2Meta?.name ?? "ockto-hash-error",
    path: erroricVm87JXc2Meta?.path ?? "/ockto/:hash()/error",
    meta: erroricVm87JXc2Meta || {},
    alias: erroricVm87JXc2Meta?.alias || [],
    redirect: erroricVm87JXc2Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/ockto/[hash]/error.vue").then(m => m.default || m)
  },
  {
    name: indexYvkvN1SOZWMeta?.name ?? "ockto-hash",
    path: indexYvkvN1SOZWMeta?.path ?? "/ockto/:hash()",
    meta: indexYvkvN1SOZWMeta || {},
    alias: indexYvkvN1SOZWMeta?.alias || [],
    redirect: indexYvkvN1SOZWMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/ockto/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: qrcodeX9EOXdTMTVMeta?.name ?? "ockto-hash-qrcode",
    path: qrcodeX9EOXdTMTVMeta?.path ?? "/ockto/:hash()/qrcode",
    meta: qrcodeX9EOXdTMTVMeta || {},
    alias: qrcodeX9EOXdTMTVMeta?.alias || [],
    redirect: qrcodeX9EOXdTMTVMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/ockto/[hash]/qrcode.vue").then(m => m.default || m)
  },
  {
    name: succesrqURj07rwuMeta?.name ?? "ockto-hash-succes",
    path: succesrqURj07rwuMeta?.path ?? "/ockto/:hash()/succes",
    meta: succesrqURj07rwuMeta || {},
    alias: succesrqURj07rwuMeta?.alias || [],
    redirect: succesrqURj07rwuMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/ockto/[hash]/succes.vue").then(m => m.default || m)
  },
  {
    name: _91token_93WJMgjsiJF0Meta?.name ?? "onboarding-signing_id-token",
    path: _91token_93WJMgjsiJF0Meta?.path ?? "/onboarding/:signing_id()/:token()",
    meta: _91token_93WJMgjsiJF0Meta || {},
    alias: _91token_93WJMgjsiJF0Meta?.alias || [],
    redirect: _91token_93WJMgjsiJF0Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/onboarding/[signing_id]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mW7M3oSMJUMeta?.name ?? "onboarding-slug",
    path: _91slug_93mW7M3oSMJUMeta?.path ?? "/onboarding/:slug()",
    meta: _91slug_93mW7M3oSMJUMeta || {},
    alias: _91slug_93mW7M3oSMJUMeta?.alias || [],
    redirect: _91slug_93mW7M3oSMJUMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/onboarding/[slug].vue").then(m => m.default || m)
  },
  {
    name: aanvraagX15g3JsqnJMeta?.name ?? "onboarding-aanvraag",
    path: aanvraagX15g3JsqnJMeta?.path ?? "/onboarding/aanvraag",
    meta: aanvraagX15g3JsqnJMeta || {},
    alias: aanvraagX15g3JsqnJMeta?.alias || [],
    redirect: aanvraagX15g3JsqnJMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: _91experience_93liUz7tdu6HMeta?.name ?? "onboarding-bedankt-experience",
    path: _91experience_93liUz7tdu6HMeta?.path ?? "/onboarding/bedankt/:experience()",
    meta: _91experience_93liUz7tdu6HMeta || {},
    alias: _91experience_93liUz7tdu6HMeta?.alias || [],
    redirect: _91experience_93liUz7tdu6HMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/onboarding/bedankt/[experience].vue").then(m => m.default || m)
  },
  {
    name: _91token_933R55Ft6wYjMeta?.name ?? "onfido-token",
    path: _91token_933R55Ft6wYjMeta?.path ?? "/onfido/:token()",
    meta: _91token_933R55Ft6wYjMeta || {},
    alias: _91token_933R55Ft6wYjMeta?.alias || [],
    redirect: _91token_933R55Ft6wYjMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/onfido/[token].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93egVQdAGcNPMeta?.name ?? "passeren-hash",
    path: _91hash_93egVQdAGcNPMeta?.path ?? "/passeren/:hash()",
    meta: _91hash_93egVQdAGcNPMeta || {},
    alias: _91hash_93egVQdAGcNPMeta?.alias || [],
    redirect: _91hash_93egVQdAGcNPMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/passeren/[hash].vue").then(m => m.default || m)
  },
  {
    name: indexZsP8hz0HrvMeta?.name ?? "privacy-verklaring",
    path: indexZsP8hz0HrvMeta?.path ?? "/privacy-verklaring",
    meta: indexZsP8hz0HrvMeta || {},
    alias: indexZsP8hz0HrvMeta?.alias || [],
    redirect: indexZsP8hz0HrvMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/privacy-verklaring/index.vue").then(m => m.default || m)
  },
  {
    name: _91fundKey_93BmbFd6XXWWMeta?.name ?? "sso-exchangeToken-fundKey",
    path: _91fundKey_93BmbFd6XXWWMeta?.path ?? "/sso/:exchangeToken()/:fundKey()",
    meta: _91fundKey_93BmbFd6XXWWMeta || {},
    alias: _91fundKey_93BmbFd6XXWWMeta?.alias || [],
    redirect: _91fundKey_93BmbFd6XXWWMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue").then(m => m.default || m)
  },
  {
    name: aanvraagqoZB1lTr7MMeta?.name ?? "sso-exchangeToken-onboarding-aanvraag",
    path: aanvraagqoZB1lTr7MMeta?.path ?? "/sso/:exchangeToken()/onboarding/aanvraag",
    meta: aanvraagqoZB1lTr7MMeta || {},
    alias: aanvraagqoZB1lTr7MMeta?.alias || [],
    redirect: aanvraagqoZB1lTr7MMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: indexA04FALuRHQMeta?.name ?? "sso-exchangeToken-onboarding",
    path: indexA04FALuRHQMeta?.path ?? "/sso/:exchangeToken()/onboarding",
    meta: indexA04FALuRHQMeta || {},
    alias: indexA04FALuRHQMeta?.alias || [],
    redirect: indexA04FALuRHQMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_933vobOzHkSBMeta?.name ?? "sso-token-id",
    path: _91id_933vobOzHkSBMeta?.path ?? "/sso/:token()/:id()",
    meta: _91id_933vobOzHkSBMeta || {},
    alias: _91id_933vobOzHkSBMeta?.alias || [],
    redirect: _91id_933vobOzHkSBMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/sso/[token]/[id].vue").then(m => m.default || m)
  },
  {
    name: index3HmMTGpwTrMeta?.name ?? "sso-token",
    path: index3HmMTGpwTrMeta?.path ?? "/sso/:token()",
    meta: index3HmMTGpwTrMeta || {},
    alias: index3HmMTGpwTrMeta?.alias || [],
    redirect: index3HmMTGpwTrMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/sso/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_9381ifaZ8MiHMeta?.name ?? "verzekeringen-hash",
    path: _91hash_9381ifaZ8MiHMeta?.path ?? "/verzekeringen/:hash()",
    meta: _91hash_9381ifaZ8MiHMeta || {},
    alias: _91hash_9381ifaZ8MiHMeta?.alias || [],
    redirect: _91hash_9381ifaZ8MiHMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250123095855/packages/investors/pages/verzekeringen/[hash].vue").then(m => m.default || m)
  }
]