<!-- eslint-disable tailwindcss/no-custom-classname -->
<script setup lang="ts">
import LegalEntitySelect from '@components/LegalEntitySelect/LegalEntitySelect.vue';

import { useMutation } from '@urql/vue';
import { computed, ref } from 'vue';
import { AppImage, AppButton } from '@mogelijk-technologies/ui-library';
import { onClickOutside } from '@vueuse/core';
import { useAuth } from '@hooks/useAuth';
import { useAuthStore } from '@stores/useAuthStore';
import { LOGOUT_MUTATION } from '@graphql/mutations/auth';

export interface Props {
  buttonId: string;
}

defineProps<Props>();

const { user, activeFunderId, authenticated, isTourActive } = toRefs(useAuthStore());

const isMenuOpen = ref(false);
const myAccount = ref(null);

const privateGroup = {
  value: 0,
  name: 'Privé',
  icon: 'user',
};

const funderGroups = computed(() => {
  return user.value?.funders?.length ? {
    type: 'group',
    label: 'Mijn bedrijf',
    icon: 'buildings',
    options: [
      ...user.value.funders.map(funder => ({
        type: 'option',
        value: funder.id as number,
        name: funder.name,
      })),
    ],
  } : {
    options: [],
  };
});

const activeFunder = computed(() => [
  privateGroup,
  ...funderGroups.value.options,
].find(item => item?.value === activeFunderId.value as number));

const { logout } = useAuth();
const logoutMutation = useMutation<undefined>(LOGOUT_MUTATION);
const handleLogout = () => logout(logoutMutation);

onMounted(() => {
  onClickOutside(myAccount, () => {
    if (isTourActive.value) return;
    isMenuOpen.value = false;
  });
});
</script>

<template>
  <div ref="myAccount" class="flex w-full flex-col tablet:w-80">
    <div class="flex w-full justify-between gap-8 rounded-t-3xl p-4" :class="{ 'bg-white': isMenuOpen }">
      <div class="flex w-full flex-row items-center gap-4">
        <div v-if="!authenticated" class="flex w-full flex-col tablet:items-end">
          <NuxtLink
            to="/login"
            class="text-sm"
            title="Inloggen"
            data-test-id="myaccount-login-button"
          >
            <div class="flex w-auto items-center gap-4 text-blue-900">
              <div class="flex size-12 items-center justify-center overflow-hidden rounded-full bg-blue-300 focus:outline-none">
                <FontAwesomeIcon :icon="['fad', 'user']" class="text-2xl" />
              </div>
              <div class="tablet:hidden">
                <p class="text-sm font-semibold">
                  Welkom
                </p>
                <p class="text-sm">
                  Gast gebruiker
                </p>
              </div>
              <div class="hidden tablet:flex">
                Inloggen
              </div>
            </div>
            <AppButton
              type="secondary"
              color="blue"
              class="!mt-4 tablet:hidden w-full"
            >
              Login
              <template #icon-end>
                <FontAwesomeIcon
                  :icon="['fas', 'right-to-bracket']"
                />
              </template>
            </AppButton>
          </NuxtLink>
        </div>
        <div v-else class="flex w-full gap-1">
          <div class="mr-3 flex size-12 shrink-0 items-center justify-center overflow-hidden rounded-full bg-blue-300 focus:outline-none">
            <AppImage v-if="user?.avatar_url" :source="user.avatar_url" class="size-12" />
            <FontAwesomeIcon v-else :icon="['fad', 'user']" class="text-2xl" />
          </div>
          <div class="flex shrink grow flex-col justify-center overflow-hidden">
            <p class="text-sm truncate font-semibold">
              {{ user?.name }}
            </p>
            <p class="text-sm truncate">
              {{ activeFunder?.name ?? 'Privé' }}
            </p>
          </div>
          <div class="flex shrink-0">
            <AppButton
              v-if="user"
              :id="buttonId"
              data-v-step-button="3"
              data-test-id="toggle-my-account-menu"
              type="secondary"
              color="blue"
              is-icon
              class="size-8"
              @click="isMenuOpen = !isMenuOpen"
            >
              <FontAwesomeIcon
                :icon="['fas', 'chevron-down']"
                :class="{ 'rotate-180': isMenuOpen }"
              />
            </AppButton>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMenuOpen" class="relative flex w-full flex-col">
      <div class="top-0 flex w-full flex-col gap-2 rounded-b-3xl bg-white p-4 pt-2 shadow-md tablet:absolute">
        <div class="rounded-md pt-1" data-v-step="2">
          <LegalEntitySelect label="Ik investeer vanuit" />
        </div>
        <div class="hidden w-auto border-t border-gray-100 py-4 tablet:flex">
          <AppButton data-test-id="myaccount-logout-button" type="tertiary" @click.prevent="handleLogout">
            <FontAwesomeIcon :icon="['fas', 'arrow-right-from-bracket']" />
            <span class="ml-2">Uitloggen</span>
          </AppButton>
        </div>
      </div>
    </div>
  </div>
</template>
